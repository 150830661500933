<!--评论列表-->
<template>
    <div class="body">
        <div class="fix-top">
            <van-tabs v-model="active" @click="onClickTab">
                <van-tab title="待评价"></van-tab>
                <van-tab title="已评价"></van-tab>
            </van-tabs>
        </div>
        <div class="comment-wrapper">
            <van-list
                    v-model="listLoading"
                    :finished="listLoadingFinished"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
                <div v-for="(item, index) in listLoadingList" :key="index">
                    <div v-if="status == 0">
                        <div class="comment-item" style="align-items: center;">
                            <img class="comment-item-img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.trademark">
                            <div class="comment-item-info">
                                <div class="comment-item-info-name" style="margin-bottom: 0.5rem;">{{item.gooods_name}}</div>
                                <div class="comment-item-info-time">{{item.create_time}}</div>
                                <div class="comment-item-info-price">￥{{moneyFen2Yuan(item.total_fee)}}</div>
                            </div>
                            <div class="comment-item-button" @click="toComment(item.id)">去评价</div>
                        </div>
                    </div>
                    <div v-else-if="status == 1">
                        <div class="comment-item">
                            <img class="comment-item-img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.trademark">
                            <div class="comment-item-info" style="width: 70%">
                                <div class="comment-item-info-name" style="width: 16rem;">{{item.gooods_name}}</div>
                                <div class="comment-item-info-star-wrapper">星级：<van-rate v-model="item.stars" readonly /></div>
                                <div class="comment-item-img-wrapper">
                                    <img class="comment-img" v-for="(item2, index2) in item.comment_img" :key="index2" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item2" @click="preView(item.comment_img,index2)">
                                </div>
                                <div class="comment-item-text-wrapper">
                                    <!--                        <div style="font-size: 1rem;">内容：</div>-->
                                    <div class="comment-item-text">{{item.comment}}</div>
                                </div>
                                <div class="comment-time">{{item.comment_time}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </van-list>
        </div>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import { globalConfig, listLoading } from '../../utils/mixin'
  import FloatNav from '../../components/common/FloatNav'
  import { moneyFen2Yuan, getUrlParams } from '../../utils/tools'
  import { ImagePreview } from 'vant'

  export default {
    name: 'CommentList',
    components: {
      FloatNav
    },
    mixins: [globalConfig, listLoading],
    data () {
      return {
        active: 2,
        status: 0,
        type: 0,
        pid: ''
      }
    },
    methods: {
      moneyFen2Yuan: function (money) {
        return moneyFen2Yuan(money)
      },
      onClickTab: function (name, title) {
        console.log(name)
        console.log(title)
        this.status = name
        this.listLoadingInit(this.apiUrl + 'mall/comment/getUserCommentList', 'list', 1, {
          status: this.status,
          pid: this.pid
        })
      },
      toComment: function (id) {
        this.$router.push('/honor/comment/' + id + '/' + this.type)
      },
      preView: function (imgArr, index) {
        const preViewArr = imgArr.map(function (value, index, array) {
          return 'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + value
        })
        console.log(preViewArr)
        ImagePreview({
          images: preViewArr,
          startPosition: index
        })
      }
    },
    mounted () {
      this.type = this.$route.params.type ? this.$route.params.type : 0
      this.pid = getUrlParams('pid')
      this.onClickTab(0, '')
    }
  }
</script>

<style scoped>
    .body {
        position:absolute;
        min-height: 100%;
        min-width: 100%;
        background-color:#f7f7f7;
    }
    .fix-top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }
    .comment-wrapper {
        display: flex;
        flex-flow: column;
        margin-top: 3rem;
    }
    .comment-item {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        background-color: #ffffff;
        margin: 1rem auto 0.5rem;
        width: 93%;
        border-radius: 0.5rem;
        box-sizing: border-box;
        padding: 1rem;
        /*box-shadow: 0 0 0.3rem #c1bfce;*/
    }
    .comment-item-img {
        width: 5rem;
        height: 5rem;
    }
    .comment-item-info {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .comment-item-info-name {
        width: 12rem;
        font-size: 1.1rem;
        font-weight: 700;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
    .comment-item-info-time {
        color: #919191;
    }
    .comment-item-info-price {
        margin-top: 0.5rem;
        color: #fa1f1f;
        font-size: 0.9rem;
    }
    .comment-item-info-star-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        font-size: 0.9rem;
        margin-top: 0.5rem;
    }
    .comment-item-button {
        border-radius: 5rem;
        background-color: #fa1f1f;
        color: #ffffff;
        box-sizing: border-box;
        padding: 0.2rem 0.7rem;
        box-shadow: 0 0 0.1rem #fa1f1f;
        font-size: 0.9rem;
    }
    .comment-item-img-wrapper {
        display: flex;
        flex-flow: wrap;
        margin-top: 0.2rem;
    }
    .comment-img {
        width: 4rem;
        height: 4rem;
        border: 0.1rem solid #d4d0e6;
        margin: 0.5rem 0.5rem 0 0;
    }
    .comment-item-text-wrapper {
        /*margin-top: 0.5rem;*/
    }
    .comment-item-text {
        font-size: 0.9rem;
        color: #7c7c7c;
        margin-top: 0.3rem;
    }
    .comment-time {
        font-size: 0.9rem;
        color: #8b8b8b;
        margin-top: 0.5rem;
    }
</style>
